import {language} from './moment';
import locales from './locales.js';

import IntlMessageFormat from 'intl-messageformat';
window.IntlMessageFormat = IntlMessageFormat;

const __localizationCache = {messages: {}};

export default function localize(key, ...rest) {
    const translatedValue = locales[language][key];

    if (!translatedValue) {
        console.warn(`'Missing translation for key ${key}`);
    }

    const messageKey = key + translatedValue;
    let translatedMessage = __localizationCache.messages[messageKey];

    if (!translatedMessage) {
        translatedMessage = new IntlMessageFormat(translatedValue, language, {});
        __localizationCache.messages[messageKey] = translatedMessage;
    }

    const args = {};

    for (let i = 0; i < rest.length; i += 2) {
        args[rest[i]] = rest[i + 1];
    }

    return translatedMessage.format(args);
}
