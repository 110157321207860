import {call, debounce, put, putResolve, select, takeEvery, takeLatest} from 'redux-saga/effects';

function recurseCategories(parent, categories, callback) {
    categories.forEach((c) => {
        callback(parent, c);
        recurseCategories(c, c.subCategories, callback);
    });
}

function* getData(pageNumber) {
    pageNumber = pageNumber || 0;

    const {minervaSettings, catalog} = yield select();

    if (!minervaSettings.headers || !minervaSettings.headers.Authorization) {
        return;
    }

    const url = new URL(
        `${minervaSettings.catalogApiUrl}/api/2.0/pagedCatalogItemResults/${minervaSettings.portalIdentifier}`,
    );

    url.searchParams.set('pageSize', catalog.pageSize);
    url.searchParams.set('pageNumber', pageNumber);

    if (catalog.searchString) {
        url.searchParams.set('searchString', catalog.searchString);
    }
    if (catalog.mandatory) {
        url.searchParams.set('mandatory', catalog.mandatory);
    }
    if (catalog.targetType !== 'all') {
        // API doesn't understand all
        url.searchParams.set('targetType', catalog.targetType);
    }

    url.searchParams.set('statusType', catalog.statusType);
    url.searchParams.set('limitToFavorites', !!catalog.limitToFavorites);
    url.searchParams.set('displayOldItems', !!catalog.displayOldItems);
    url.searchParams.set('displayFutureItems', !!catalog.displayFutureItems);

    if (catalog.selectedCategoryIds.length) {
        url.searchParams.set('categoryIds', catalog.selectedCategoryIds.join(','));
    }

    const response = yield call(fetch, url, {
        headers: minervaSettings.headers,
    });

    return yield call([response, response.json]);
}

/**
 * Resets all data and loads the first page using the default (or stored) filters
 * @param {object} action The action
 */
function* doCatalogRefresh(action) {
    yield call(doSetSearch, {
        payload: {
            loadingEnabled: true,
            pageNumber: 0,
            pageSize: 25,
            results: [],
            searchString: '',
            limitToFavorites: false,
            statusType: 'all',
            targetType: 'all',
            mandatory: false,
            displayOldItems: false,
            displayFutureItems: false,
            selectedCategoryIds: [],
            ...action.payload,
        },
    });
}

/**
 * Loads the (next) page from the paging API
 */
function* doCatalogLoad() {
    const catalog = yield select((x) => x.catalog);

    // When pageNumber > 0 we need to check if loading is needed..
    if (catalog.pageNumber > 0) {
        // When pageSize >= the totalResults returned there must be a filter active so do not fetch more data
        if (catalog.pageSize >= catalog.totalResults) return;
        // Only call getData when we know there's more data
        if (!catalog.totalResults || catalog.results.length >= catalog.totalResults) return;
    }

    const obj = yield call(getData, catalog.pageNumber);

    let categories = catalog.categories;

    if (!categories) {
        const {minervaSettings} = yield select();

        if (!minervaSettings.headers || !minervaSettings.headers.Authorization) {
            return;
        }

        const categoryResponse = yield call(
            fetch,
            `${minervaSettings.catalogApiUrl}/api/1.0/GetCatalogCategoriesForFilter/${minervaSettings.portalIdentifier}`,
            {headers: minervaSettings.headers},
        );

        categories = yield call([categoryResponse, categoryResponse.json]);
    }

    recurseCategories(null, categories, (parent, c) => {
        c.parent = parent;
        c.selected = false;
        c.descendantSelected = false;

        let facet;

        if (obj.facetResults) {
            facet = obj.facetResults.CategoryIds.values.find((f) => parseInt(f.range) === c.id);
        }

        if (facet) {
            c.count = facet.count;
        } else {
            c.count = 0;
        }

        if (catalog.selectedCategoryIds.some((x) => x === c.id)) {
            c.selected = true;
            let parent = c.parent;

            while (parent) {
                parent.descendantSelected = true;
                parent = parent.parent;
            }
        }
    });

    obj.results.forEach((catalogItem) => {
        catalogItem.completed = false;
        catalogItem.aboutToExpire = false;
        catalogItem.started = false;
        catalogItem.expired = false;

        // TODO: Is this still needed? Then move this to the API
        if (catalogItem.bestOrLast) {
            if (catalogItem.bestOrLast.decision >= 95) {
                catalogItem.completed = true;
            } else if (catalogItem.bestOrLast.decision >= 91) {
                catalogItem.completed = true;
                catalogItem.aboutToExpire = true;
            } else if (catalogItem.bestOrLast.decision >= 81) {
                catalogItem.completed = true;
            } else if (catalogItem.bestOrLast.decision >= 50) {
                catalogItem.started = true; // started
            } else if (catalogItem.bestOrLast.decision >= 40) {
                catalogItem.started = true; // also started
            } else if (catalogItem.bestOrLast.decision > 30) {
                catalogItem.expired = true;
            } else if (catalogItem.bestOrLast.decision > 20) {
                catalogItem.failed = true;
            }
        }
    });

    const results = [...catalog.results, ...obj.results];

    const payload = {
        ...catalog,
        ...obj,
        results: results,
        totalResults: obj.totalResults,
        categories: categories,
        pageNumber: catalog.pageNumber + 1,
        loading: false,
    };

    yield put({type: 'CATALOG_LOADED', payload: payload});
}

function* doCatalogReport(action) {
    yield put({type: 'CATALOG_REPORT_LOADING', payload: true});

    let catalog = yield select((x) => x.catalog);
    let length = catalog.results.length;
    let totalResults = catalog.totalResults;

    while (length !== totalResults) {
        yield doCatalogLoad();
        catalog = yield select((x) => x.catalog);
        length = catalog.results.length;
        totalResults = catalog.totalResults;
    }
    const data = {
        reportInfo: {
            onlyAssignToFunctions: true, // <true| false > TODO
            origin: `${window.location.protocol}//${window.location.host}`,
        },
        items: catalog.results,
    };

    yield put({...action, type: 'REPORT_FETCH', data});
}

function* doSetSearch(action) {
    const catalog = yield select((x) => x.catalog);

    if (!catalog) return;

    const defaults = {
        pageNumber: 0,
        pageSize: 25,
        results: [],
        searchString: '',
        limitToFavorites: false,
        statusType: 'all',
        targetType: 'all',
        mandatory: false,
        selectedCategoryIds: [],
    };

    const newCatalog = {
        ...defaults,
        ...catalog,
        ...action.payload,
    };
    // avoid circular references in JSON stringify
    const skipParents = (key, value) => {
        if (key === 'parent') return;
        return value;
    };

    // Check if we actually have changes
    if (JSON.stringify(catalog, skipParents) === JSON.stringify(newCatalog, skipParents)) {
        return;
    }

    yield putResolve({
        type: 'CATALOG_SETPARAMS',
        payload: {
            ...newCatalog,
            pageNumber: 0,
            results: [],
            loading: true,
        },
    });

    yield put({type: 'CATALOG_PARAMSSET'});
}

function* doCheckChanged(action) {
    const catalog = yield select((x) => x.catalog);

    if (!catalog) return;

    const selectedCategoryIds = [...catalog.selectedCategoryIds];
    const checked = action.payload.checked;
    const categoryId = action.payload.categoryId;

    if (typeof checked === 'undefined') {
        return;
    }

    let checkedCategory;

    // use the recurse method to do a recursive find
    recurseCategories(null, catalog.categories, (parent, c) => {
        if (c.id === categoryId) {
            checkedCategory = c;
        }
    });

    const addOrRemoveId = (checked, items, id) => {
        if (checked) {
            if (!items.find((x) => x === id)) {
                items.push(id);
            }
        } else {
            const index = selectedCategoryIds.findIndex((x) => x === id);

            if (index >= 0) {
                if (checkedCategory.id === categoryId) {
                    selectedCategoryIds.splice(index, 1);
                } else if (
                    checkedCategory.subCategories.some((x) =>
                        selectedCategoryIds.some((y) => y === x.id),
                    )
                ) {
                    // Don't turn off this one. This happens when parent + all childs was selected
                    // and you unselect a child.
                }
            }
        }
    };

    addOrRemoveId(checked, selectedCategoryIds, categoryId);

    recurseCategories(null, checkedCategory.subCategories, (parent, c) => {
        addOrRemoveId(checked, selectedCategoryIds, c.id);
    });

    // check if all childs of the parent are equal. If so: the parent is no longer indeterminate
    if (checkedCategory.parent) {
        // select parent when all childs are selected
        if (
            checked &&
            checkedCategory.parent.subCategories.every((x) =>
                selectedCategoryIds.some((y) => y === x.id),
            )
        ) {
            addOrRemoveId(checked, selectedCategoryIds, checkedCategory.parent.id);
        }

        // unselect parent when there's one selected child 'missing'
        if (
            !checked &&
            !checkedCategory.parent.subCategories.every((x) =>
                selectedCategoryIds.some((y) => y === x.id),
            )
        ) {
            addOrRemoveId(checked, selectedCategoryIds, checkedCategory.parent.id);
        }
    }

    // Check for changes
    if (JSON.stringify(catalog.selectedCategoryIds) === JSON.stringify(selectedCategoryIds)) {
        return;
    }

    yield putResolve({
        type: 'CATALOG_SETSEARCH',
        payload: {selectedCategoryIds: selectedCategoryIds},
    });
}

function* doDebouncedCatalogLoad() {
    yield put({type: 'CATALOG_LOAD'});
}

export function* catalogSaga() {
    yield takeEvery('CATALOG_SETSEARCH', doSetSearch);
    yield takeEvery('CATALOG_CATEGORY_CHECK_CHANGED', doCheckChanged);

    yield takeLatest('CATALOG_REFRESH', doCatalogRefresh);
    yield takeLatest('CATALOG_LOAD', doCatalogLoad);
    yield takeLatest('CATALOG_REPORT', doCatalogReport);

    yield debounce(1000, 'CATALOG_PARAMSSET', doDebouncedCatalogLoad);
}
