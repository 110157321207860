import m from 'moment/dist/moment.js';
import 'moment/dist/locale/nl.js';

let l = (navigator.language || navigator.browserLanguage || 'en').substring(0, 2).toLowerCase();

switch (l) {
    case 'nl':
    case 'en':
        break;
    default:
        l = 'en';
}

m.locale(l);

export default m;

export const language = l;
