import {AppLocalizeBehavior} from './app-localize-behavior.js';
import moment, {language} from './moment';
import localize from './localize';
import locales from './locales.js';

export const TcgLocalizeBehavior = [
    AppLocalizeBehavior,
    {
        created: function () {
            this.language = language;
            this.moment = moment;
            this.resources = locales;
        },
    },
];

export function tcgLocalizeBehaviorMixin(Base) {
    if (Base === null) {
        throw new Error('tcgLocalizeBehaviorMixin(Base) expects a base class');
    }

    return class TcgLocalizeElement extends Base {
        constructor() {
            super();
            this.moment = moment;
            this.language = language;
            this.resources = locales;
        }

        static get properties() {
            return {
                moment: {
                    type: Object,
                },
            };
        }

        localize(key, ...rest) {
            return localize(key, ...rest);
        }
    };
}
